import { ROLE_STAFF_OPS, ROLE_STAFF_MANAGER, ROLE_STAFF_SALES, ROLE_ADMINISTRATIVE, ROLE_ADMIN, ROLE_STAFF_PANIC } from '~/const/user/roles'
import { useAuthStore } from '~/stores/auth'
import { defineNuxtRouteMiddleware, navigateTo } from '#app'

export default defineNuxtRouteMiddleware((to) => {
    const auth = useAuthStore()
    const authorizedRoleIds = [ROLE_STAFF_OPS, ROLE_STAFF_SALES, ROLE_ADMIN, ROLE_STAFF_MANAGER, ROLE_STAFF_PANIC]

    if (!auth.authenticated && to.meta.layout !== 'auth') {
        return navigateTo('/login')
    }

    if ([ROLE_ADMINISTRATIVE].includes(auth.user.role)) {
        return navigateTo('/documents')
    }

    if (!authorizedRoleIds.includes(auth.user.role)) {
        return navigateTo('/login')
    }
})
